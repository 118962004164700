$primaryColor: var(--primary-color);

.serialNumberCell {
	font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: $primaryColor !important;
	text-decoration: none;
}

.searchFieldContainer {
	padding-bottom: 20px;
	padding-top: 20px;
	display: flex;
	gap: 16px;
}

.searchField {
	width: 500px;
}

.resetButton {
	color: $primaryColor !important;
	padding: 6px 16px !important;
	height: 39px;
}

.noDevicesMessage {
	font-family: Noto Sans, Noto Sans Mono, sans-serif;
    text-align: center;
    margin-top: 50px; 
    font-size: 1.5rem; 
    color: #67808C; 
    padding: 20px;
    border-radius: 8px;
    background-color: #f2f2f2;
}